import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { ChibbityQuestion } from "../data/DataModels";
import { RootState } from "./store";
import { ALLOWED_GUESSES_CNT } from "../data/AppConst";

export const fetchDailyTriviaData = createAsyncThunk(
  "dailyTrivia/fetchDailyTriviaData",
  async () => {
    const response = await axios.post(
      "https://api.triviaowls.xyz/v1/dailyTrivia",
      { withCredentials: true }
    );
    return response.data as ChibbityQuestion[];
  }
);

export interface Answer {
  answerText: string;
  isCorrect: boolean;
  hintShown: boolean;
}

export interface UserAnswerHistory {
  questionId: string;
  questionIdx: number;
  answerGuesses: Answer[];
  questionComplete: boolean;
  answeredCorrectly: boolean | null;
  hintAcitvated: boolean;
}

export interface DailyTriviaState {
  dailyTriviaQuestions: ChibbityQuestion[];
  dailyQuestionIdx: number;
  triesPerQuestion: Record<string, number>;
  userAnswers: UserAnswerHistory[];
  isQuizFinished: boolean;
  progressToFreePlay: boolean;
  loading: boolean;
  error: string | null;
}

export const initialState: DailyTriviaState = {
  dailyTriviaQuestions: [],
  dailyQuestionIdx: 0,
  triesPerQuestion: {},
  userAnswers: [],
  isQuizFinished: false,
  progressToFreePlay: false,
  loading: false,
  error: null,
};

export interface AddAnswerGuessPayload {
  questionId: string;
  questionIdx: number;
  answer: string;
  isCorrect: boolean;
  hintShown: boolean;
}

const dailyTriviaSlice = createSlice({
  name: "dailyTriviaData",
  initialState,
  reducers: {
    setTriviaData(state, action: PayloadAction<ChibbityQuestion[]>) {
      state.dailyTriviaQuestions = action.payload;
    },
    setQuizFinished(state) {
      state.isQuizFinished = true;
    },
    setHintActivated(state, action: PayloadAction<string>) {
      const updatedAnswers = state.userAnswers.map((item) => {
        if (item.questionId === action.payload) {
          return {
            ...item,
            hintAcitvated: true,
          };
        }
        return item;
      });
      state.userAnswers = updatedAnswers;
    },
    setDailyQuestionIdx(state, action: PayloadAction<number>) {
      state.dailyQuestionIdx = action.payload;
    },
    setProgressToFreePlay(state) {
      state.progressToFreePlay = true;
    },
    setDailyQuestionGiveUp(state, action: PayloadAction<string>) {
      const updatedAnswers = state.userAnswers.map((item) => {
        if (item.questionId === action.payload) {
          return {
            ...item,
            questionComplete: true,
            answeredCorrectly: false,
          };
        }
        return item;
      });
      state.userAnswers = updatedAnswers;
    },
    incrementDailyQuestionIdx(state) {
      let new_question_idx = null;
      for (let i = state.dailyQuestionIdx; i < state.userAnswers.length; i++) {
        if (!state.userAnswers[i].questionComplete) {
          new_question_idx = i;
          break;
        }
      }
      if (new_question_idx === null) {
        for (let i = state.dailyQuestionIdx; i > 0; i--) {
          if (!state.userAnswers[i].questionComplete) {
            new_question_idx = i;
            break;
          }
        }
      }

      if (new_question_idx !== null) {
        state.dailyQuestionIdx = new_question_idx;
      }
    },
    decrementDailyQuestionIdx(state) {
      state.dailyQuestionIdx = Math.max(state.dailyQuestionIdx - 1, 0);
    },
    incrementTriesForCurrentQuestion(state, action: PayloadAction<string>) {
      const questionId = action.payload;
      if (!state.triesPerQuestion[questionId]) {
        state.triesPerQuestion[questionId] = 1;
      } else {
        state.triesPerQuestion[questionId] += 1;
      }
    },
    // addCorrectAnswerGuess(
    //   state,
    //   action: PayloadAction<AddAnswerGuessPayload>
    // ) {
    //   const existingAnswers = state.userAnswers.map((item) => {
    //     if (item.questionId === action.payload.questionId) {
    //       return {
    //         ...item,
    //         correctAnswer: action.payload.correctAnswer,
    //       };
    //     }
    //     return item;
    //   });
    //   state.userAnswers = existingAnswers;
    // },
    addAnswerGuess(state, action: PayloadAction<AddAnswerGuessPayload>) {
      let questions_completed = 0;
      const updatedAnswers = state.userAnswers.map((item) => {
        let question_complete = false;
        if (item.questionId === action.payload.questionId) {
          const newGuess = {
            answerText: action.payload.answer,
            isCorrect: action.payload.isCorrect,
            hintShown: action.payload.hintShown,
          };

          let guessed_correctly = null;
          if (
            action.payload.isCorrect ||
            item.questionComplete ||
            item.answerGuesses.length === ALLOWED_GUESSES_CNT - 1
          ) {
            questions_completed++;
            question_complete = true;
          }
          if (action.payload.isCorrect) {
            guessed_correctly = true;
          } else if (item.answerGuesses.length === ALLOWED_GUESSES_CNT - 1) {
            guessed_correctly = false;
          }
          return {
            ...item,
            answerGuesses: [newGuess, ...item.answerGuesses],
            questionComplete: question_complete,
            answeredCorrectly: guessed_correctly,
          };
        }
        if (
          item.questionComplete ||
          item.answerGuesses.length === ALLOWED_GUESSES_CNT
        ) {
          questions_completed++;
        }
        return item;
      });

      if (questions_completed === 5) {
        state.isQuizFinished = true;
      }
      state.userAnswers = updatedAnswers;
    },
    finishQuiz(state) {
      state.isQuizFinished = true;
    },
    loadQuizStateFromStorage(state) {
      try {
        const savedState = localStorage.getItem("isQuizFinished");
        if (savedState !== null) {
          state.isQuizFinished = JSON.parse(savedState);
        }
        const dailyQuestionIdx = localStorage.getItem("dailyQuestionIdx");
        if (dailyQuestionIdx !== null) {
          state.dailyQuestionIdx = JSON.parse(dailyQuestionIdx);
        }
        const triesPerQuestion = localStorage.getItem("triesPerQuestion");
        if (triesPerQuestion !== null) {
          state.triesPerQuestion = JSON.parse(triesPerQuestion);
        }
        const userAnswerHistory = localStorage.getItem("userAnswers");
        if (userAnswerHistory !== null) {
          state.userAnswers = JSON.parse(userAnswerHistory);
        }
      } catch (error) {
        console.error("Error loading state from localStorage:", error);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDailyTriviaData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchDailyTriviaData.fulfilled,
        (state, action: PayloadAction<ChibbityQuestion[]>) => {
          state.loading = false;
          state.dailyTriviaQuestions = action.payload;

          if (state.userAnswers.length === 0) {
            state.userAnswers = action.payload.map((question, idx) => ({
              questionId: question.question_id,
              questionIdx: idx,
              answerGuesses: [],
              questionComplete: false,
              answeredCorrectly: null,
              progressToFreePlay: false,
              hintAcitvated: false,
            }));
          }
        }
      )
      .addCase(fetchDailyTriviaData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? "Something went wrong";
      });
  },
});

export const selectUserAnswersForQuestion = (state: RootState, id: string) => {
  const triviaState = state.dailyTrivia as { userAnswers: UserAnswerHistory[] };

  return triviaState.userAnswers.find(
    (answer: UserAnswerHistory) => answer.questionId === id
  );
};

export const {
  incrementDailyQuestionIdx,
  incrementTriesForCurrentQuestion,
  finishQuiz,
  loadQuizStateFromStorage,
  setDailyQuestionIdx,
  decrementDailyQuestionIdx,
  addAnswerGuess,
  setDailyQuestionGiveUp,
  setProgressToFreePlay,
  setTriviaData,
  setHintActivated,
  setQuizFinished,
} = dailyTriviaSlice.actions;
export default dailyTriviaSlice.reducer;
