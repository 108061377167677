import * as React from "react";
// import SvgIcon from "@mui/material/SvgIcon";
import FlexBox from "../../legacy_components/utils/FlexBox";

export default function SitemarkIcon() {
  return (
    <>
      <FlexBox dir="row">
        <FlexBox dir="col">
          <img
            style={{ maxWidth: "50px" }}
            src="/media/trivial_owl_blue.png"
            alt="logo"
          />
          {/* Trivia Owl API */}
        </FlexBox>
      </FlexBox>
    </>
  );
}
