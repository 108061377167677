import Button from "@mui/material/Button";
import FlexBox from "../../legacy_components/utils/FlexBox";
import {
  Box,
  Container,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import {
  setProgressToFreePlay,
  UserAnswerHistory,
} from "../../redux/dailyTriviaSlice";
import { useEffect, useState } from "react";
// import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import styles from "./DailyQuizFinishedUpsell.module.css";
import { ALLOWED_GUESSES_CNT } from "../../data/AppConst";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  flexGrow: 1,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
  wordWrap: "break-word",
}));

const guesses_scale = new Map<number, string>([
  [5, "🤯"],
  [6, "🤩"],
  [7, "😏"],
  [8, "😎"],
  [9, "😀"],
  [10, "😅"],
  [11, "🥲"],
  [12, "🫣"],
  [13, "🤨"],
  [14, "😭"],
  [15, "🤡"],
]);

const correct_scale = new Map<number, string>([
  [5, "🤯"],
  [4, "🤩"],
  [3, "😎"],
  [2, "🫣"],
  [1, "🤨"],
  [0, "🤡"],
]);

const give_up_scale = new Map<number, string>([
  [5, "🤡"],
  [4, "😭"],
  [3, "🤨"],
  [2, "🫣"],
  [1, "🥲"],
  [0, "🤩"],
]);

export type FeedbackStats = {
  label: string;
  count: number;
  emoji: string;
};

export default function DailyQuizFinishedUpsell() {
  const [stats, setStats] = useState<FeedbackStats[]>([]);
  const [correct, setCorrect] = useState<number>(0);
  const [shareText, setShareText] = useState<string[]>([]);
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();

  const dailyTriviaData = useSelector((state: RootState) => state.dailyTrivia);

  const onCopyShareText = () => {
    const date = new Date();
    const full_date =
      date.getMonth() +
      "/" +
      date.getDate() +
      "/" +
      `${date.getFullYear()}`.slice(2, 4);

    const extra_data = ["Daily Five", full_date];
    const converted_text = [...extra_data, ...shareText].join("\n");
    navigator.clipboard.writeText(converted_text).then(() => {
      setVisible(true);

      setTimeout(() => {
        setVisible(false);
      }, 1000);
    });
  };

  const onContinueToFreePlay = () => {
    dispatch(setProgressToFreePlay());
  };

  useEffect(() => {
    const stats = user_feedback_stats(dailyTriviaData.userAnswers);
    setStats(stats);
    const wordle_text = createWordleString(dailyTriviaData.userAnswers);
    setShareText(wordle_text);
  }, [dailyTriviaData.userAnswers]);

  const user_feedback_stats = (
    user_answers: UserAnswerHistory[]
  ): FeedbackStats[] => {
    let total_guesses = 0;
    let total_correct = 0;
    let total_given_up = 0;
    let total_hints = 0;
    // let perfect_game = false;

    for (const item of user_answers) {
      total_guesses += item.answerGuesses.length;
      if (item.answeredCorrectly) {
        total_correct++;
      }
      if (
        item.answeredCorrectly === false &&
        item.answerGuesses.length !== ALLOWED_GUESSES_CNT
      ) {
        total_given_up++;
      }
      if (item.hintAcitvated) {
        total_hints++;
      }
    }
    // if (total_correct === 5 && total_guesses === 5) {
    //   perfect_game = true;
    // }
    setCorrect(total_correct);
    return [
      {
        label: "Correct",
        count: total_correct,
        emoji: correct_scale.get(total_correct) ?? "",
      },
      {
        label: "Guesses",
        count: total_guesses,
        emoji: guesses_scale.get(total_guesses) ?? "🥲",
      },
      {
        label: "Hints",
        count: total_hints,
        emoji: give_up_scale.get(total_hints) ?? "🥲",
      },
      {
        label: "Gave up",
        count: total_given_up,
        emoji: give_up_scale.get(total_given_up) ?? "",
      },
    ];
  };

  const createWordleString = (userAnswers: UserAnswerHistory[]) => {
    return userAnswers.map((question) => {
      let result = "";

      question.answerGuesses.forEach((guess) => {
        if (guess.isCorrect) {
          if (guess.hintShown) {
            result = "🟢" + result;
          } else {
            result = "🟩" + result;
          }
        } else {
          if (guess.hintShown) {
            result = "🟡" + result;
          } else {
            result = "🟨" + result;
          }
        }
      });

      if (
        question.questionComplete &&
        !question.answeredCorrectly &&
        question.answerGuesses.length < ALLOWED_GUESSES_CNT
      ) {
        if (question.hintAcitvated) {
          result += "⚫";
        } else {
          result += "⬛";
        }
      }
      // console.log(result)
      // console.log(result.length)
      // while (Array.from(result).length < 3) {
      //   result += "⬜";
      // }
      return result;
    });
  };

  return (
    <Container
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 2 },
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: "auto",
          typography: "body1",
          justifyContent: "center",
          maxWidth: "100%",
        }}
      >
        <FlexBox dir="row">
          <Typography variant="h4" color="textPrimary">
            Daily Five
          </Typography>
        </FlexBox>
        <FlexBox dir="row">
          <FlexBox dir="column">
            <Typography
              variant="h6"
              color="textSecondary"
              style={{
                margin: "10px",
                textWrap: "pretty",
                textAlign: "center",
              }}
            >
              Nice job, you got {correct} out of 5 questions!
            </Typography>
            <Stack spacing={2} justifyContent={"center"}>
              {stats.map((item, i) => {
                return (
                  <Item
                    key={item.label + i}
                    style={{
                      backgroundColor: true ? "primary" : "secondary",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      width: "80%",
                      margin: "0 auto",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      fontWeight={900}
                      color="textSecondary"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "row",
                      }}
                    >
                      <div style={{ minWidth: "30%" }}>{item.label}</div>
                      <div>{item.count}</div>
                      <div>{item.emoji}</div>
                    </Typography>
                  </Item>
                );
              })}
            </Stack>
            <br />
            <FlexBox dir="row">
              <Typography variant="h6" color="textPrimary">
                Click to share your score
              </Typography>
              {/* <Button style={{width: "0px"}}>
                <ContentCopyIcon
                  style={{ position: "relative" }}
                /> */}
              {/* </Button> */}
            </FlexBox>
            <Box
              sx={{
                padding: "auto",
                typography: "body1",
                justifyContent: "center",
                width: "auto",
                minWidth: "150px",
                maxWidth: "250px",
                border: "1px solid black",
                borderRadius: "20px",
                margin: "0 auto",
                backgroundColor: "grey",
              }}
              onClick={onCopyShareText}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "row",
                }}
              >
                <Typography
                  variant="h6"
                  color="textPrimary"
                  style={{ position: "relative", margin: "10px" }}
                >
                  {shareText.map((item) => {
                    return (
                      <>
                        {item}
                        <br />
                      </>
                    );
                  })}
                </Typography>
              </div>
            </Box>
            <FlexBox dir="row">
              <Typography
                variant="h6"
                color="textPrimary"
                className={visible ? styles.visible : styles.hidden}
                style={{ padding: "10px" }}
              >
                Copied to clipboard!
              </Typography>
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <FlexBox dir="row">
          <Button onClick={onContinueToFreePlay}>
            <h4>Continue to Free Play</h4>
          </Button>
        </FlexBox>
      </Box>
    </Container>
  );
}
