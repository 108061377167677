import * as React from "react";
// import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import InputLabel from "@mui/material/InputLabel";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { visuallyHidden } from "@mui/utils";
import { styled } from "@mui/material/styles";

const StyledBox = styled("div")(({ theme }) => ({
  alignSelf: "center",
  width: "80vw",
  minWidth: "200px",
  // maxWidth: "800px",
  justifyContent: "center",
  // height: 400,
  height: "25vh",

  marginTop: theme.spacing(8),
  borderRadius: theme.shape.borderRadius,
  outline: "6px solid",
  outlineColor: "hsla(220, 25%, 80%, 0.2)",
  border: "1px solid",
  borderColor: theme.palette.grey[200],
  boxShadow: "0 0 12px 8px hsla(220, 25%, 80%, 0.2)",
  backgroundImage: "url(media/hero.png)",
  backgroundSize: "cover",
  [theme.breakpoints.up("sm")]: {
    marginTop: theme.spacing(10),
    height: "50vh",
  },
  ...theme.applyStyles("dark", {
    boxShadow: "0 0 24px 12px hsla(210, 100%, 25%, 0.2)",
    backgroundImage: "url(media/hero.png)",
    outlineColor: "hsla(220, 20%, 42%, 0.1)",
    borderColor: theme.palette.grey[700],
  }),
}));

export default function Hero() {
  return (
    // <Box
    //   id="hero"
    //   sx={(theme) => ({
    //     width: '100%',
    //     backgroundRepeat: 'no-repeat',

    //     backgroundImage:
    //       'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)',
    //     ...theme.applyStyles('dark', {
    //       backgroundImage:
    //         'radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 16%), transparent)',
    //     }),
    //   })}
    // >
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pt: { xs: 14, sm: 20 },
        pb: { xs: 8, sm: 12 },
      }}
    >
      <Stack
        spacing={2}
        useFlexGap
        sx={{ alignItems: "center", width: { xs: "100%", sm: "70%" } }}
      >
        <Typography
          variant="h1"
          sx={{
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            alignItems: "center",
            fontSize: "clamp(3rem, 10vw, 3.5rem)",
          }}
        >
          Ask&nbsp;only&nbsp;the&nbsp;
          <Typography
            component="span"
            variant="h1"
            sx={(theme) => ({
              fontSize: "inherit",
              color: "secondary.light",
              ...theme.applyStyles("dark", {
                color: "primary.dark",
              }),
            })}
          >
            best
          </Typography>
        </Typography>
        <Typography
          sx={{
            textAlign: "center",
            color: "text.secondary",
            width: { sm: "100%", md: "80%" },
          }}
        >
          Sign up to learn more about the Trivia Owl, the world's largest and
          most performant trivia API.
        </Typography>
        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={1}
          useFlexGap
          sx={{ pt: 2, width: { xs: "100%", sm: "350px" } }}
        >
          <InputLabel htmlFor="email-hero" sx={visuallyHidden}>
            Email
          </InputLabel>
          <TextField
            id="email-hero"
            hiddenLabel
            size="small"
            variant="outlined"
            aria-label="Enter your email address"
            placeholder="Your email address"
            fullWidth
            slotProps={{
              htmlInput: {
                autoComplete: "off",
                "aria-label": "Enter your email address",
              },
            }}
          />
          <Button
            variant="contained"
            color="primary"
            size="small"
            sx={{ minWidth: "fit-content" }}
          >
            Start now
          </Button>
        </Stack>
        <Typography
          variant="caption"
          color="text.secondary"
          sx={{ textAlign: "center" }}
        >
          By clicking &quot;Start now&quot; you agree to our&nbsp;
          <Link href="#" color="primary">
            Terms & Conditions
          </Link>
          .
        </Typography>
      </Stack>
      {/* <img src="media/hero.png" alt="/public/media/hero.png"></img> */}
      <StyledBox id="image" />
    </Container>
    // </Box>
  );
}
