import { useEffect, useRef, useState } from "react";
import {
  ChibbityCheckAnswerResponse,
  ChibbityQuestion,
} from "../../data/DataModels";
import styles from "./PlayCard.module.css";
import Button from "@mui/material/Button";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { PlaySource, SegmentOptions } from "../../data/Enums";
import FlexBox from "../../legacy_components/utils/FlexBox";
import StandardButton from "../../legacy_components/interaction_components/StandardButton";
import TextField from "@mui/material/TextField";
// import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import {
  Box,
  Container,
  LinearProgress,
  Paper,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  addAnswerGuess,
  Answer,
  incrementDailyQuestionIdx,
  selectUserAnswersForQuestion,
  setDailyQuestionGiveUp,
  setHintActivated,
} from "../../redux/dailyTriviaSlice";
import { RootState } from "../../redux/store";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import { ALLOWED_GUESSES_CNT } from "../../data/AppConst";
import { incrementCorrectCount } from "../../redux/randomTriviaSlice";

/**************
 *
 *
 *
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * MUST UPDATE QUERY
 * Hint sometimes null, maybe verified, maybe answers under a certain length
 * also quey hash might not be totally random, all questions were about music
 */

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
  flexGrow: 1,
  ...theme.applyStyles("dark", {
    backgroundColor: "#1A2027",
  }),
  wordWrap: "break-word",
}));

export default function PlayCard({
  source,
  question,
  votedQs,
  setVotedQs,
}: {
  source: PlaySource;
  question: ChibbityQuestion;
  votedQs: Set<string>;
  setVotedQs: React.Dispatch<React.SetStateAction<Set<string>>>;
}) {
  const [inputAns, setInputAns] = useState<string>("");
  const [nextQuestionProgress, setNextQuestionProgress] = useState<number>(0);
  const [shuffledAnswers, setShuffledAnswers] = useState<string[]>([]);
  const [inputPlaceholder, setInputPlaceholder] = useState<string>("");
  const [currSegment, setCurrSegment] = useState<string>(SegmentOptions.REVIEW);
  const [incorrectInputAnimationActive, setIncorrectInputAnimationActive] =
    useState(false);
  const [correctInputAnimationActive, setCorrectInputAnimationActive] =
    useState(false);
  const [showUserWasCorrect, setShowUserWasCorrect] = useState<boolean>(false);
  const [answerEntered, setAnswerEntered] = useState<boolean>(false);
  const [gaveUp, setGaveUp] = useState<boolean>(false);
  const [isGiveUpDisabled, setIsGaveUpDisabled] = useState<boolean>(false);
  const [userAnsweredCorrectly, setUserAnsweredCorrectly] = useState<
    boolean | null
  >(null);

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Set focus on the input element
    }
  }, [question, inputRef, inputAns]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus(); // Set focus on the input element
    }
  }, []);

  const dispatch = useDispatch();
  const { dailyQuestionIdx } = useSelector(
    (state: RootState) => state.dailyTrivia
  );

  const singleQuestionUserAnswers = useSelector((state: RootState) =>
    selectUserAnswersForQuestion(state, question?.question_id ?? "")
  );

  const [showHint, setShowHint] = useState<boolean>(
    singleQuestionUserAnswers?.hintAcitvated ?? false
  );

  const handleHintButtonClick = () => {
    setTimeout(() => {
      // setIsHintDisabled(true);
      dispatch(setHintActivated(question.question_id));
    }, 400);

    setShowHint(!showHint);
  };

  const handleGaveUpButtonClick = () => {
    setTimeout(() => {
      setIsGaveUpDisabled(true);
    }, 400);
    dispatch(setDailyQuestionGiveUp(question.question_id));
    setGaveUp(true);
    if (source === PlaySource.DAILY) {
      handleDailyPageIncrement();
    }
  };

  const checkAnswer = (ans: string) => {
    setAnswerEntered(true);
    setTimeout(() => {
      setAnswerEntered(false);
    }, 500);
    const response = axios.post(
      "https://api.triviaowls.xyz/v1/checkAnswer",
      {
        question_id: question.question_id,
        user_answer: ans,
        accepted_correct_answers: question.accepted_correct_answers,
      },
      { withCredentials: true }
    );
    setInputPlaceholder("");

    response.then((response) => {
      const check_ans_data = response.data as ChibbityCheckAnswerResponse;

      if (check_ans_data.user_answer_correct) {
        handleCorrectAns(ans);
      } else {
        handleIncorrectAns(ans);
      }
    });
  };

  const handleDailyPageIncrement = () => {
    setTimeout(() => {
      dispatch(incrementDailyQuestionIdx());
    }, 2000);
  };

  // const handleRandomPageIncrement = () => {
  //   setTimeout(() => {
  //     dispatch(incrementRandomQuestionIdx()); // Adjust by 1 as page is 1-based and index is 0-based
  //   }, 2000);
  // };

  const handleCorrectAns = (ans: string) => {
    // setCorrectAnswer(true);
    setCorrectInputAnimationActive(true);
    setTimeout(() => {
      setCorrectInputAnimationActive(false);
    }, 500);
    setShowUserWasCorrect(true);
    dispatch(
      addAnswerGuess({
        questionId: question.question_id,
        questionIdx: dailyQuestionIdx,
        answer: ans,
        isCorrect: true,
        hintShown: showHint,
      })
    );
    setUserAnsweredCorrectly(true);
    if (source === PlaySource.DAILY) {
      handleDailyPageIncrement();
    } else if (source === PlaySource.RANDOM) {
      dispatch(incrementCorrectCount());
      // handleRandomPageIncrement();
    }
  };

  const handleIncorrectAns = (ans: string) => {
    setIncorrectInputAnimationActive(true);

    setTimeout(() => {
      setIncorrectInputAnimationActive(false);
    }, 500);
    setInputPlaceholder("");

    dispatch(
      addAnswerGuess({
        questionId: question.question_id,
        questionIdx: dailyQuestionIdx,
        answer: ans,
        isCorrect: false,
        hintShown: showHint,
      })
    );
    if (
      singleQuestionUserAnswers?.answerGuesses.length ===
        ALLOWED_GUESSES_CNT - 1 &&
      source === PlaySource.DAILY
    ) {
      handleDailyPageIncrement();
    } else if (source === PlaySource.RANDOM) {
      setUserAnsweredCorrectly(false);
    }
  };

  useEffect(() => {
    if (
      currSegment === SegmentOptions.HINT ||
      currSegment === SegmentOptions.ANSWER
    ) {
      setCurrSegment("");
    }
    setInputAns("");
    setShowUserWasCorrect(false);
    // setIsHintDisabled(false);
    setShowHint(false);
    setInputPlaceholder("");
    setIsGaveUpDisabled(false);
    setUserAnsweredCorrectly(null);
    setGaveUp(false);
    setNextQuestionProgress(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  const show_progress_bar =
    source === PlaySource.DAILY &&
    (singleQuestionUserAnswers?.answeredCorrectly === false ||
      userAnsweredCorrectly === false ||
      userAnsweredCorrectly === true ||
      gaveUp);

  useEffect(() => {
    if (
      show_progress_bar &&
      nextQuestionProgress !== 100 &&
      source === PlaySource.DAILY
    ) {
      setTimeout(() => {
        setNextQuestionProgress(nextQuestionProgress + 10);
      }, 150);
    }
  }, [
    nextQuestionProgress,
    userAnsweredCorrectly,
    gaveUp,
    singleQuestionUserAnswers,
    source,
    show_progress_bar,
  ]);

  useEffect(() => {
    if (question && question.accepted_correct_answers) {
      let loc_shuffled_answers: string[] = [];
      if (question.question_type === "multiple_choice") {
        loc_shuffled_answers = [
          ...(question.accepted_correct_answers ?? []),
          ...(question.incorrect_multiple_choices ?? []),
        ];
        for (let i = 0; i < loc_shuffled_answers.length; i++) {
          const rand_idx = Math.floor(Math.random() * i);
          const temp = loc_shuffled_answers[rand_idx];
          loc_shuffled_answers[rand_idx] = loc_shuffled_answers[i];
          loc_shuffled_answers[i] = temp;
        }
      }
      setShuffledAnswers(loc_shuffled_answers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question]);

  const handleKeyPress = (e: any, ans: string) => {
    if (e.key === "Enter") {
      checkAnswer(ans);
    }
  };

  if (!question) {
    return <></>;
  }

  const cannot_guess_anymore =
    singleQuestionUserAnswers?.questionComplete ?? false;

  return (
    <Container
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: { xs: 3, sm: 6 },
      }}
    >
      <div className={styles.question_container}>
        <FlexBox dir="col">
          <div className={styles.question_answer_wrapper}>
            {/* <h2 className={styles.pill_placer}></h2> */}
            <Typography
              variant="h5"
              color="textPrimary"
              className={styles.question}
            >
              {question.question}
            </Typography>
            {/* <hr style={{ width: "100%" }}></hr> */}
            <Box
              sx={{
                position: "relative",
                // left: "15%",
                width: "100%",
                margin: "0px",
                padding: "10px",
              }}
            >
              {show_progress_bar ? (
                <LinearProgress
                  variant="determinate"
                  value={nextQuestionProgress}
                />
              ) : (
                <></>
              )}
              {!show_progress_bar ? (
                <LinearProgress variant="determinate" value={100} />
              ) : (
                <></>
              )}
            </Box>
            {singleQuestionUserAnswers?.answeredCorrectly ||
            userAnsweredCorrectly ? (
              <>
                <Typography variant="h5" color="textSecondary">
                  You got it! <br />
                  <br />
                </Typography>
                <Typography variant="h4" color="textPrimary">
                  {question.accepted_correct_answers[0]}
                </Typography>
              </>
            ) : source === PlaySource.RANDOM && gaveUp ? (
              <Typography variant="h4" color="textPrimary">
                {question.accepted_correct_answers[0]}
              </Typography>
            ) : source === PlaySource.DAILY &&
              (singleQuestionUserAnswers?.answeredCorrectly === false ||
                userAnsweredCorrectly === false) ? (
              <>
                <Typography variant="h5" color="textSecondary">
                  All out of guesses! The correct answer was... <br />
                  <br />{" "}
                </Typography>
                <Typography variant="h4" color="textPrimary">
                  {question.accepted_correct_answers[0]}{" "}
                </Typography>
              </>
            ) : source === PlaySource.DAILY ? (
              <Typography variant="h5" color="textSecondary">
                {ALLOWED_GUESSES_CNT -
                  (singleQuestionUserAnswers?.answerGuesses.length ?? 0)}{" "}
                guess
                {ALLOWED_GUESSES_CNT -
                  (singleQuestionUserAnswers?.answerGuesses.length ?? 0) ===
                1
                  ? ""
                  : "es"}{" "}
                left
              </Typography>
            ) : (
              <></>
            )}

            <div className={styles.question_section}>
              <FlexBox dir="column">
                {cannot_guess_anymore || showUserWasCorrect ? (
                  <></>
                ) : question.question_type === "short_answer" ? (
                  <div
                    style={{
                      position: "relative",
                      display: "flex",
                      justifyContent: "space-between",
                      flexDirection: "row",
                      // width: "150%",
                      // left: "-25%",
                      // margin: "0 auto",
                    }}
                  >
                    {" "}
                    {/* <Button
                      onClick={handleHintButtonClick}
                      color={"primary"}
                      variant={"contained"}
                      disabled={
                        singleQuestionUserAnswers?.hintAcitvated ?? false
                      }
                    >
                      Show hint
                    </Button> */}
                    <div onKeyDown={(e) => handleKeyPress(e, inputAns)}>
                      <TextField
                        inputRef={inputRef}
                        // color={"primary"}
                        variant="filled"
                        className={
                          source === PlaySource.DAILY && answerEntered
                            ? styles.enter_pulse
                            : source === PlaySource.RANDOM &&
                              incorrectInputAnimationActive
                            ? styles.incorrect_animation
                            : source === PlaySource.RANDOM &&
                              correctInputAnimationActive
                            ? styles.correct_animation
                            : styles.normal_no_animation
                        }
                        value={inputPlaceholder}
                        onChange={(e) => {
                          setInputAns(e.target.value);
                          setInputPlaceholder(e.target.value);
                        }}
                        placeholder={
                          cannot_guess_anymore
                            ? "no more guesses :("
                            : "answer me!"
                        }
                        disabled={cannot_guess_anymore}
                      />
                      {/* <Button
                        variant="contained"
                        color="primary"
                        style={{ height: "100%" }}
                      >
                        <DoubleArrowIcon color="inherit" />
                      </Button> */}
                    </div>
                    {/* <Button
                      onClick={handleGaveUpButtonClick}
                      color={"primary"}
                      disabled={isGiveUpDisabled}
                    >
                      Give up!
                    </Button> */}
                  </div>
                ) : question.question_type === "true_false" ? (
                  <>
                    <StandardButton fx={() => checkAnswer("true")}>
                      True
                    </StandardButton>
                    <StandardButton fx={() => checkAnswer("false")}>
                      False
                    </StandardButton>
                  </>
                ) : question.question_type === "multiple_choice" ? (
                  <div className={styles.multiple_choice_buttons_container}>
                    {shuffledAnswers.map((question_answer, idx) => {
                      return (
                        <div key={question.question_id + idx}>
                          <StandardButton
                            fx={() => checkAnswer(question_answer)}
                          >
                            {question_answer}
                          </StandardButton>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  <></>
                )}
              </FlexBox>
            </div>
            <Box
              sx={{
                padding: "auto",
                typography: "body1",
                justifyContent: "center",
                width: "auto",
                minWidth: "20px",
                maxWidth: "300px",
                margin: "0 auto",
              }}
            >
              <Stack spacing={2} justifyContent={"center"}>
                {singleQuestionUserAnswers?.answerGuesses.map(
                  (answer_item: Answer, i: number) => {
                    return (
                      <Item
                        key={question.question_id + i}
                        style={{
                          backgroundColor: answer_item.isCorrect
                            ? "primary"
                            : "secondary",
                        }}
                        className={
                          i === 0 && incorrectInputAnimationActive
                            ? styles.incorrect_animation
                            : i === 0 && correctInputAnimationActive
                            ? styles.correct_animation
                            : styles.normal_no_animation
                        }
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-around",
                            flexDirection: "row",
                          }}
                        >
                          <FlexBox dir="col">
                            <div style={{ width: "10%" }}>
                              {answer_item.isCorrect ? (
                                <DoneIcon color="success" />
                              ) : (
                                <CloseIcon color="error" />
                              )}
                            </div>
                          </FlexBox>

                          <div
                            style={{
                              width: "80%",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            {answer_item.answerText}
                          </div>
                          <div
                            style={{
                              width: "10%",
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                            }}
                          >
                            {singleQuestionUserAnswers?.answerGuesses.length -
                              i}
                          </div>
                        </div>
                      </Item>
                    );
                  }
                )}
              </Stack>
            </Box>

            {cannot_guess_anymore ? (
              <></>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    flexDirection: "row",
                    // maxWidth: "50vw",
                    marginTop: 40,
                  }}
                >
                  {/* <StandardButton
                    fx={handleHintButtonClick}
                    color={"primary"}
                    disabled={singleQuestionUserAnswers?.hintAcitvated ?? false}
                  >
                    Show hint
                  </StandardButton> */}
                  <Button
                    onClick={handleHintButtonClick}
                    color={"primary"}
                    variant={"contained"}
                    disabled={singleQuestionUserAnswers?.hintAcitvated ?? false}
                    // style={{ maxWidth: "10vw" }}
                  >
                    Show hint
                  </Button>
                  <div style={{ width: 40 }}></div>
                  {/* <StandardButton
                    fx={handleGaveUpButtonClick}
                    color={"primary"}
                    disabled={isGiveUpDisabled}
                  >
                    Give up!
                  </StandardButton> */}
                  <Button
                    onClick={handleGaveUpButtonClick}
                    color={"primary"}
                    variant={"contained"}
                    disabled={isGiveUpDisabled}
                    // style={{ maxWidth: "10vw" }}
                  >
                    Give up!
                  </Button>
                </div>
                <br />
                <div className={styles.answer_toggle_section}>
                  {((singleQuestionUserAnswers?.hintAcitvated ?? false) &&
                    source === PlaySource.DAILY) ||
                  (showHint && source === PlaySource.RANDOM) ? (
                    <Typography variant="h5" color="textSecondary">
                      Hint: {question.hint}
                    </Typography>
                  ) : (
                    <>
                      <br />
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </FlexBox>
      </div>
    </Container>
  );
}
